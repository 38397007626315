<template>
<div>
	<div class="cover cover--image-top cover--medium" :style="'background-image: url(' + require('@/assets/cover-about.jpg') + ')'"></div>
	<div class="blue-box">
		<div class="wrapper wrapper--spaced wrapper--centered jumbotron">
			<h1 class="title title--white title--spaced">Time for a New Political Alternative in B.C.?</h1>
			<p class="text">We’re a group of British Columbians who believe this province deserves better. We believe it needs a real, authentic opposition that holds the NDP’s feet to the fire and proposes real solutions to the real problems facing taxpayers.</p>
			<p class="text">Over the coming weeks and months, we will be exploring all possibilities on how best to realize this vision, and give British Columbians the democratic options that they deserve.</p>
			<br>
			<h1 class="title title--white title--spaced">Common Sense BC Steering Committee</h1>
			<ul class="list">
				<li>Executive Director: Aaron Gunn</li>
				<li>Communications Director: Angelo Isidorou</li>
				<li>Campaign Director: Connor Gibson</li>
				<li>Steve  Schafer</li>
				<li>Harman Bhangu</li>
				<li>Richard Gauvin</li>
				<li>Aisha Estey</li>
				<li>Wes Graham</li>
			</ul>
		</div>
	</div>
   <div class="blue-box blue-box--light jumbotron">
      <div class="wrapper wrapper--spaced wrapper--centered">
         <h1 class="title title--white title--spaced">Join our movement to bring common<br>sense back to British Columbia</h1>
         <form  class="form form--boxless form--column" style="display: inline-flex; margin:auto;" method="post" action="https://commonsensebc.com/webservices/forms.php">
            <div style="max-width: 600px; display: flex; flex-wrap: wrap;">
               <div class="form__item form__item--duo form__item--large"><input name="firstname" type="text" placeholder="First Name*" class="cover__input" autocomplete="given-name" required/></div>
               <div class="form__item form__item--duo form__item--large"><input name="lastname" type="text" placeholder="Last Name*" class="cover__input" autocomplete="family-name" required/></div>
               <div class="form__item form__item--duo form__item--large"><input name="email" type="email" placeholder="Email Address*" class="cover__input" autocomplete="email" required/></div>
               <div class="form__item form__item--duo form__item--large"><input name="zip" type="text" placeholder="Postal Code*" class="cover__input cover__input--small" autocomplete="postal-code" pattern="[A-Za-z][0-9][A-Za-z][\s]?[0-9][A-Za-z][0-9]" required/></div>
					<div class="form__item"><label class="option"><input type="checkbox" name="volunteer" value="yes"/> I would like to learn more about becoming a volunteer</label></div>
				</div>
            <input type="hidden" name="source" value="commonsensebc_join"/>
            <input style="max-width: 600px; margin-top: 20px;" type="submit" class="button button--yellow button--large" value="Register" />
         </form>
      </div>
   </div>

</div>
</template>

<script>
import facebookMixin from '@/videos-mixin.js'
export default {
	mixins: [facebookMixin],
	components: {

	},
	created() {

	},
	data() {
		return {

		}
	},
	computed: {
		getVideoComputed () {
			return this.getFbVideo(2050689965209103);
		}
	}
}
</script>

<style scoped>
.video-views { background: #F3F1F1; padding: 60px; }
.video-count { display: flex; text-align: left; color: #E0231C; }
.video-count__number { font-size: 7rem; }
.video-count__text { font-size: 2.9rem; padding-left: 10px; text-transform: uppercase; font-weight: 600; }

@media (max-width: 1100px) {
	.video-views { padding: 30px 35px; display: flex; justify-items: center; flex-direction: column; margin-bottom: 40px; }
}

</style>
