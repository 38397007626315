export default {
    created () {
        this.$fbHelperPromise.then(fbHelper => {
            this.fbVideos = fbHelper.videos.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
            this.fbPlaylists = fbHelper.playlists;
            this.youtubeVideos = [
                this.buildVideoObject("5Ewl51xk4Fk", "Official Trailer", "", new Date("Nov 19, 2020").toISOString(), 720, 405, this.getFbVideo("414660473038371").thumbnailUrl, this.getFbVideo("414660473038371").duration),
                this.buildVideoObject("xJy_-qLBUTY", "E1 - Do First Nations Actually Oppose Pipelines?", "", new Date("Nov 19, 2020").toISOString(), 720, 405, this.getFbVideo("413611323351112").thumbnailUrl, this.getFbVideo("413611323351112").duration),
                this.buildVideoObject("dpS4wOtxaXs", "E2 - The Truth About How Canada Treats Child Murderers", "", new Date("Nov 19, 2020").toISOString(), 720, 405, this.getFbVideo("385144149235840").thumbnailUrl, this.getFbVideo("385144149235840").duration),
                this.buildVideoObject("dbIi2tNKsNs", "E3 - Meet the Activists Opposing Pipelines in Canada", "", new Date("Nov 19, 2020").toISOString(), 720, 405, this.getFbVideo("509085363440538").thumbnailUrl, this.getFbVideo("509085363440538").duration),
                this.buildVideoObject("9-28BDdbEfo", "E4 - The Rise of Cancel Culture in Canada", "", new Date("Nov 19, 2020").toISOString(), 720, 405, "https://i.ytimg.com/vi_webp/9-28BDdbEfo/maxresdefault.webp", 1215),
                this.buildVideoObject("Yo-vIsr2MYg", "E5 - The True Story of Canada’s First Prime Minister", "", new Date("Nov 19, 2020").toISOString(), 720, 405, this.getFbVideo("3522193234539281").thumbnailUrl, this.getFbVideo("3522193234539281").duration),
                this.buildVideoObject("KRIhwlPSz5A", "E6 - Is Tanker Traffic in Canada Safe for the Environment?", "", new Date("Nov 19, 2020").toISOString(), 720, 405, this.getFbVideo("431244718045881").thumbnailUrl, this.getFbVideo("431244718045881").duration),
            ]
        });
    },

    data() {
        return {
            fbVideos: [],
            fbPlaylists: [],
            youtubeVideos: []
        }
    },

    methods: {
        hasFbVideos () {
            return this.fbVideos && this.fbVideos.length > 0;
        },
        getFbTrendingVideos () {
            return this.fbVideos.filter(video => video.playlistId == "546953869500394").slice(0, 4);
        },
        getFbPolExplainedOneVideos () {
            return this.fbVideos.filter(video => video.playlistId == "1262274614154547");
        },
        getFbPolExplainedTwoVideos () {
            return this.fbVideos.filter(video => video.playlistId == "4664266063687653");
        },        
        getFbC2CVideos () {
            return this.fbVideos.filter(video => video.playlistId == "2531100657022530");
        },        
        getFbLatestVideos(skip, take) {
            return this.fbVideos.slice(skip, take+skip);
        },
        getFbVideo (videoId) {
            return this.fbVideos.find(video => video.id == videoId);
        },
        getYoutubeVideo (videoId) {
            return this.youtubeVideos.find(video => video.id == videoId);
        },
        getFbPlaylistVideos (playlistId) {
            return this.fbVideos.filter(video => video.playlistId == playlistId).slice(0, 6);
        },
        buildVideoObject (id, title, description, createdDate, width, height, thumbnailUrl, duration) {
            return { 
                id, 
                title, 
                description, 
                createdDate, 
                width, 
                height, 
                thumbnailUrl, 
                duration 
            };
        },
        toggleFbVideoPopup (videoId) {
            this.toggleVideoDOMNode(this.generateFbVideoFrameHtmlElement(videoId));
        },
        toggleYoutubeVideoPopup (videoId) {
            this.toggleVideoDOMNode(this.generateYoutubeVideoFrameHtmlElement(videoId));
        },
        toggleVideoDOMNode(fbIframe) {
            var videoDOMNode = document.getElementById('video_player');
            if(videoDOMNode) videoDOMNode.remove();
            else {
                var videoPlayer = document.createElement("div"); 
                videoPlayer.id = "video_player";
                videoPlayer.classList.add('video_player--open');
                videoPlayer.addEventListener("click", this.toggleVideoDOMNode.bind(null), false);
                videoPlayer.appendChild(fbIframe);
                document.body.appendChild(videoPlayer);
            }
        },
        generateFbVideoFrameHtmlElement(videoId) {
            var video = this.getFbVideo(videoId);
            var videoFrameSize = this.calculateFrameSize(video.width, video.height);
            var iframe = document.createElement("iframe");     
            iframe.setAttribute("src", encodeURI(
                "https://www.facebook.com/plugins/video.php?href=" + 
                "https://www.facebook.com/AaronGunn.ca/videos/" + videoId + "/" +
                "&show_text=0&mute=0" + "&width=" + videoFrameSize.width + "&height=" + videoFrameSize.height
            ));
            iframe.setAttribute("width", videoFrameSize.width);            
            iframe.setAttribute("height", videoFrameSize.height);
            iframe.setAttribute("style", "border:none;overflow:hidden");
            iframe.setAttribute("scrolling", "no");
            iframe.setAttribute("frameborder", 0);
            iframe.setAttribute("allowTransparency", "true");
            iframe.setAttribute("allowFullScreen", "true");
            return iframe;
        },
        generateYoutubeVideoFrameHtmlElement(videoId) {
            var video = this.getYoutubeVideo(videoId);
            var videoFrameSize = this.calculateFrameSize(video.width, video.height);
            var iframe = document.createElement("iframe");     
            iframe.setAttribute("src", encodeURI("https://www.youtube.com/embed/" + videoId));
            iframe.setAttribute("width", videoFrameSize.width);            
            iframe.setAttribute("height", videoFrameSize.height);
            iframe.setAttribute("frameborder", 0);
            iframe.setAttribute("allowFullScreen", "true");
            return iframe;
        },        
        calculateFrameSize(videoWidth, videoHeight) {
            const VIEWPORT_MARGIN = 0.25;
            var viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
            var viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
            var maxFrameWidth = Math.min(viewportWidth * (1 - VIEWPORT_MARGIN), videoWidth);
            var maxFrameHeight = Math.min(viewportHeight * (1 - VIEWPORT_MARGIN), videoHeight);
            var biggestBoundReduction = Math.max(videoWidth / maxFrameWidth, videoHeight / maxFrameHeight);
            var adjustedWidth = Math.round(videoWidth / biggestBoundReduction);
            var adjustedHeight = Math.round(videoHeight / biggestBoundReduction);
            return { width: adjustedWidth, height: adjustedHeight };
        },
        getFormatedDate (stringDate) {
            var date = new Date(stringDate);
            const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
            return months[date.getMonth()] + " " + date.getDate() + ", " + date.getFullYear();
        },
        getMinutesFromSeconds(seconds) {
            return Math.floor(seconds / 60) + ':' + ('0' + Math.floor(seconds % 60)).slice(-2);
        },
        getCroppedText (text, maxCharacters) {
            return text ? (
                (text.length <= maxCharacters) ? text 
                    : (text.slice(0, maxCharacters) + "..."))
                : "";
        }
    }
}